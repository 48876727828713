import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import Seo from '../components/seo';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  body: {
    marginBottom: theme.spacing(2),
  },
  logo: {
    maxHeight: 400,
    width: '100%',
    marginRight: theme.spacing(1),
  },
}));

export default function TellSpin(props) {
  const classes = useStyles();
  return (
    <Layout>
      <Seo
        title={'Success'}
        meta={[
          {
            name: 'googlebot',
            content: 'noindex, nofollow',
          },
          {
            name: 'robots',
            content: 'noindex, nofollow',
          },
        ]}
      />
      <Container maxWidth="lg" component="main" className={classes.main}>
        <Typography className={classes.title} variant="h1">
          Success!
        </Typography>
        <Typography className={classes.body} variant="body1">
          We've sent a message to help you get started.
        </Typography>
        <Typography className={classes.body} variant="body1">
          Open your Slack workspace and navigate to the DO a Thing app to begin.
        </Typography>
        <div className="mt-10 w-full ">
          <StaticImage
            src="../images/do-a-thing-message.png"
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="You have a message from DOaThing"
            layout="fullWidth"
          />
        </div>
      </Container>
    </Layout>
  );
}
